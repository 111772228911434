import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../layout/Loader'
import Menu from '../layout/Menu';
import Footer from '../layout/Footer';
import axios from 'axios';
import { PLUS_ONE_API_URL } from './baseURL';

function Dashboard() {

    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';
        var element = document.getElementById("page-container");
        element.classList.add("show");
        getTotalUser();
        getMoment();
    }, []);
    const [users, setUsers] = useState(0);
    const [moment, setMoment] = useState(0);

    const getTotalUser = () => {
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('auth_code', "PlUsOnE$123");
        const myurl = `${PLUS_ONE_API_URL}api/admin/get-users`;
        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            if (response.data.success === true) {
                setUsers(response.data.data.length);
            }
        });
    };

    const getMoment = ()=>{
        var bodyFormData3 = new URLSearchParams();
        bodyFormData3.append('auth_code', "PlUsOnE$123");
        const myurl4 = `${PLUS_ONE_API_URL}api/admin/all-moments`;
         axios({method: "post",
                url: myurl4,
                data: bodyFormData3,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },})
                .then((response) => {
                    if (response.data.success === true) {
                        setMoment(response.data.data.length);
                    }
                });
    }
        
                    

    return (
        <>
            <Loader />
            <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item"><a>Home</a></li>
                        <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                    <h1 className="page-header">Dashboard </h1>
                    <div className="row">
                        <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats bg-info">
                                <div className="stats-icon"><i className="fa fa-users"></i></div>
                                <div className="stats-info">
                                    <h4>Total Users</h4>
                                    <p>{users}</p>
                                </div>
                                <div className="stats-link">
                                    <Link to="/users">View Detail <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats bg-orange">
                                <div className="stats-icon"><i className="fa fa-star"></i></div>
                                <div className="stats-info">
                                    <h4>Total Momenta</h4>
                                    <p>{moment}</p>
                                </div>
                                <div className="stats-link">
                                    <Link to='/moments'>View Detail <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats bg-blue">
                                <div className="stats-icon"><i className="fa fa-building"></i></div>
                                <div className="stats-info">
                                    <h4>Total Customers</h4>
                                    <p>20000</p>
                                </div>
                                <div className="stats-link">
                                    <Link to="/customers">View Detail <i className="fa fa-arrow-alt-circle-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats bg-red">
                                <div className="stats-icon"><i className="fa fa-shopping-cart"></i></div>
                                <div className="stats-info">
                                    <h4>Total Orders</h4>
                                    <p>150</p>
                                </div>
                                <div className="stats-link">
                                    <a >View Detail <i className="fa fa-arrow-alt-circle-right"></i></a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Dashboard
