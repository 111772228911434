import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../layout/Loader';
import Menu from '../layout/Menu';
import Footer from '../layout/Footer';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { PLUS_ONE_API_URL } from './baseURL';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditHowPlusOneWorks = () => {
    const location = useLocation();
    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';
        var element = document.getElementById("page-container");
        element.classList.add("show");
        getPlusOneDetails();
    }, []);

    const getPlusOneDetails=()=>{
        console.log(location.state.data);
        let loc = location.state.data;
        setPlusOneDetail({
            serial:loc.serial_no,
            title:loc.title,
            video_link:loc.video
        })
        setDesc(loc.description);
    }

    const [plusOneDetail, setPlusOneDetail] = useState({
        serial: "",
        title:"",
        video_link:"",
    });
    const [desc,setDesc]=useState('');
    const [status, setStatus] = useState('');
    const [errors, setErrors] = useState({});
    const [disable, setDisable] = useState(false);
    const navigate = useNavigate();

    const handleDesc = (e, editor) => {
        setDesc(editor.getData());
    }

    const handleVideo = (e) => {
        let vid = e.target.files[0];
        console.log(vid.type === 'video/mp4' || vid.type === 'video/mkv' );
        if (vid.type === 'video/mp4' || vid.type === 'video/mkv' ) {
            setStatus('Uploading .......');
            var bodyFormData = new FormData();
            bodyFormData.append('image', vid)
            const myurl = `${PLUS_ONE_API_URL}api/admin/upload-img`;
            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/form-data' },
            }).then((response) => {
                if (response.data.success === true) {
                    setStatus('Uploaded !!')
                    let res = response.data.data;
                    setPlusOneDetail({...plusOneDetail, video_link: res.url });
                }
            });
        } else {
            setErrors({ ...errors, image_err: "Invalid video format" })
        }
    }

    const handleReset = () => {
        setPlusOneDetail({
            serial:"",
            title:"",
            video_link:""
        })
        setDesc("");
    }

    const validateDetails = () => {
        let isValid = true;
        let err = {};

        if (!plusOneDetail.video_link) {
            isValid = false;
            err['video_err'] = "Please select any Video";
        }
        
        if (!plusOneDetail.title) {
            isValid = false;
            err['title'] = "Title Required";
        }

        if (!plusOneDetail.serial) {
            isValid = false;
            err['serial'] = "Serial No. Required";
        }

        if (!desc) {
            isValid = false;
            err['desc'] = "Description Required";
        }

        setErrors(err);
        return isValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateDetails()) {
            setDisable(true);
            var bodyFormData = new URLSearchParams();
            bodyFormData.append('auth_code', "PlUsOnE$123");
            bodyFormData.append('serial_no',parseInt(plusOneDetail.serial) );
            bodyFormData.append('description',desc);
            bodyFormData.append('video', plusOneDetail.video_link); 
            bodyFormData.append('step_id', location.state.data.id);
            bodyFormData.append('title', plusOneDetail.title);
            const myurl = `${PLUS_ONE_API_URL}api/admin/update-plus-one-step`;
            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }).then((response) => {
                if (response.data.success === true) {
                    setDisable(false);
                    toast.success("GiftCard added successfully");
                    setTimeout(() => {
                        navigate("/how-plusone-works");
                    }, [1000])
                    console.log(response);
                } else {
                    setDisable(false);
                    toast.error("Something went wrong");
                }
            });
        }
    }


    return (
        <>
            <ToastContainer />
            <Loader />
            <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item"><Link to="/how-plusone-works">How PlusOne Works</Link></li>
                        <li className="breadcrumb-item active">Edit How Plusone Works</li>
                    </ol>
                    <h1 className="page-header">Edit How Plusone Works</h1>

                    <div className="row">
                        <div className="col-xl-12 ui-sortable">
                            <div className="panel panel-inverse" data-sortable-id="form-stuff-10">
                                <div className="panel-heading ui-sortable-handle">
                                    <h4 className="panel-title">Edit How Plusone Works</h4>
                                    <Link to="/giftcard" className="btn btn-sm btn-default pull-right">Back</Link>
                                </div>


                                <div className="panel-body">
                                    <form onSubmit={(e) => handleSubmit(e)}>
                                        <fieldset>
                                        <div className="row">
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="exampleInputName">Serial no:</label>
                                                    <input type="number" className="form-control" placeholder="Enter Serial no." value={plusOneDetail.serial} onChange={(e)=>setPlusOneDetail({...plusOneDetail,serial:e.target.value})}/>
                                                    <div className="text-danger">{errors.serial}</div>
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="exampleInputName">Title:</label>
                                                    <input type="text" className="form-control" placeholder="Enter Title"  value={plusOneDetail.title}  onChange={(e)=>setPlusOneDetail({...plusOneDetail,title:e.target.value})} />
                                                    <div className="text-danger">{errors.title}</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-md-6 form-group">
                                            <CKEditor id="editor" name="aboutUs" data={desc} editor={ClassicEditor} onChange={(e, editor) => { handleDesc(e, editor) }} style={{ borderRadius: '15px' }} />
                                            <div className="text-danger">{errors.desc}</div>
                                            </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="exampleInputAddress">Video:</label>
                                                    <input className="form-control mb-10" type="file" accept="video/*" onChange={handleVideo} />
                                                    <div >{status}</div>
                                                    {plusOneDetail.video_link ? <video src={plusOneDetail.video_link} alt="giftcard" style={{ width: "auto", height: "200px", objectFit: "cover", marginTop: "15px" }} autoPlay controls/> : ""}
                                                    <div className="text-danger">{errors.video_err}</div>
                                                </div>

                                            </div>
                                            <button type="submit" className="btn btn-sm btn-success m-r-5" disabled={disable} >{disable ? 'Processing...' : 'Update'}</button>
                                            <button type="reset" className="btn btn-sm btn-default" onClick={handleReset}>Reset</button>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default EditHowPlusOneWorks