import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Users from './pages/Users';
import AddUser from './pages/AddUser';
import Contractors from './pages/Contractors';
import EditUser from './pages/EditUser';
import Category from './pages/Category';
import AddCategory from './pages/AddCategory';
import EditCategory from './pages/EditCategory';
import TopRatedMoments from './pages/TopRatedMoments';
import AddTopRatedMoments from './pages/AddTopRatedMoments';
import EditTopRatedMoments from './pages/EditTopRatedMoments';
import Moments from './pages/Moments';
import EditMoment from './pages/EditMoment';
import NewPassword from './pages/NewPassword';
import Voucher from './pages/Voucher';
import AddVoucher from './pages/AddVoucher';
import EditVoucher from './pages/EditVoucher';
import GiftCard from './pages/GiftCard';
import AddGiftCard from './pages/AddGIftCard';
import HowPlusOneWorkds from './pages/HowPlusOneWorks';
import AddHowPlusOneWorks from './pages/AddHowPlusOneWoeks';
import EditHowPlusOneWorks from './pages/EditHowPlusOneWorks';
import QuestionAnswer from './pages/QuestionAnswer';
import AddQuestionAnswer from './pages/AddQuesionAnswer';
import Rating from './pages/Rating';
import Feedback from "./pages/Feedback";

const Markup = () => {
    return (
        <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/users' element={<Users />} />
            <Route path='/contractors' element={<Contractors />} />
            <Route path='/add-user' element={<AddUser />} />
            <Route path='/edit-user' element={<EditUser />} />
            <Route path='/category' element={<Category />} />
            <Route path='/add-category' element={<AddCategory />} />
            <Route path='/edit-category' element={<EditCategory />} />
            <Route path='/top-rated-moments' element={<TopRatedMoments />} />
            <Route path='/add-top-rated-moments' element={<AddTopRatedMoments />} />
            <Route path='/edit-top-rated-moments' element={<EditTopRatedMoments />} />
            <Route path='/moments' element={<Moments/>}/>
            <Route path='/edit-moments' element={<EditMoment/>}/>
            <Route path='/new-password/:id' element={<NewPassword/>}/>
            <Route path='/voucher' element={<Voucher/>}/>
            <Route path='/add-voucher' element={<AddVoucher/>}/>
            <Route path='/edit-voucher' element={<EditVoucher/>}/>
            <Route path='/giftcard' element={<GiftCard/>}/>
            <Route path='/add-giftcard' element={<AddGiftCard/>}/>
            <Route path='/how-plusone-works' element={<HowPlusOneWorkds/>}/>
            <Route path='/add-how-plusone-works' element={<AddHowPlusOneWorks/>}/>
            <Route path='/edit-how-plusone-works' element={<EditHowPlusOneWorks/>}/>
            <Route path='/question-answer' element={<QuestionAnswer/>}/>
            <Route path='/add-question-answer' element={<AddQuestionAnswer/>}/>
            <Route path='/rating' element={<Rating/>}/>
            <Route path='/feedback' element={<Feedback/>}/>
            
        </Routes>
    )
}

export default Markup