import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../layout/Loader'
import Menu from '../layout/Menu';
import Footer from '../layout/Footer';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { PLUS_ONE_API_URL } from './baseURL';

const Feedback = () => {

    let navigate = useNavigate();
    const [Feedback, setFeedback] = useState([]);
    const [displayFeedback, setDisplayFeedback] = useState([]);
    const [deleteId, setDeleteId] = useState('');
    const [alert, setAlert] = useState(false);
    const [alert2, setAlert2] = useState(false);

    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';
        var element = document.getElementById("page-container");
        element.classList.add("show");
        getFeedbackList();
    }, []);

    const getFeedbackList = () => {
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('auth_code', "PlUsOnE$123");
        const myurl = `${PLUS_ONE_API_URL}api/admin/get-feedback-list?auth_code=PlUsOnE$123`;
        axios({
            method: "get",
            url: myurl,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            setFeedback(response.data.data);
                setDisplayFeedback(response.data.data);
            console.log(response.data.data);
        });
    };

    const handleSearch = (e) => {
        if (e.target.value) {
            let search = e.target.value;
            setDisplayFeedback(Feedback.filter(
                (elem) =>
                    elem.id.toLowerCase().includes(search.toLowerCase())
            ));
        } else {
            setDisplayFeedback(Feedback)
        }
    }

    return (
        <Fragment>
            <Loader />
            <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Feedback</li>
                    </ol>
                    <h1 className="page-header">Feedback
                    </h1>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table id="product-listing" className="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>User Name</th>
                                                    <th>Profile</th>
                                                    <th>Topic</th>
                                                    <th>Details</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displayFeedback.length > 0 ? displayFeedback.map((elem, index) =>
                                                    <tr key={index}>
                                                        <td align='left'>{index + 1}</td>
                                                        <td>{elem.user_name}</td>
                                                        <td><img src={elem.profile_pic} alt='user-img' style={{ width: '50px', height: '50px', borderRadius: '8px', objectFit: 'cover', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} /></td>
                                                        <td>{elem.feedback_about}</td>
                                                        <td>{elem.detail_feedback}</td>
                                                        <td>{elem.date}</td>
                                                    </tr>
                                                ) : <tr><td >No Record Found..</td></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Fragment >
    )
}

export default Feedback