import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../layout/Loader'
import Menu from '../layout/Menu';
import Footer from '../layout/Footer';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { PLUS_ONE_API_URL } from './baseURL';

const Rating = () => {

    let navigate = useNavigate();
    const [Rating, setRating] = useState([]);
    const [displayRating, setDisplayRating] = useState([]);
    const [deleteId, setDeleteId] = useState('');
    const [alert, setAlert] = useState(false);
    const [alert2, setAlert2] = useState(false);

    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';
        var element = document.getElementById("page-container");
        element.classList.add("show");
        getRatingList();
    }, []);

    const getRatingList = () => {
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('auth_code', "PlUsOnE$123");
        const myurl = `${PLUS_ONE_API_URL}api/admin/list-rating?auth_code=PlUsOnE$123`;
        axios({
            method: "get",
            url: myurl,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            setRating(response.data.data);
                setDisplayRating(response.data.data);
            console.log(response);
        });
    };

    const handleSearch = (e) => {
        if (e.target.value) {
            let search = e.target.value;
            setDisplayRating(Rating.filter(
                (elem) =>
                    elem.id.toLowerCase().includes(search.toLowerCase())
            ));
        } else {
            setDisplayRating(Rating)
        }
    }

    return (
        <Fragment>
            <Loader />
            <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Rating</li>
                    </ol>
                    <h1 className="page-header">Rating
                        {/* <Link to="/add-Rating" className="btn btn-success" style={{ marginLeft: "10px" }}><i className="fa fa-plus"></i></Link> */}
                    </h1>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table id="product-listing" className="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>From</th>
                                                    <th>To</th>
                                                    <th>Rate</th>
                                                    <th>Message</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displayRating.length > 0 ? displayRating.map((elem, index) =>
                                                    <tr key={index}>
                                                        <td align='left'>{index + 1}</td>
                                                        <td>{elem.plus_one_first_name} {elem.plus_one_last_name}</td>
                                                        <td>{elem.user_first_name} {elem.user_last_name}</td>
                                                        <td>{elem.rate}</td>
                                                        <td>{elem.review}</td>
                                                    </tr>
                                                ) : <tr><td >No Record Found..</td></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Fragment >
    )
}

export default Rating