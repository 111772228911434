import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../layout/Loader";
import Menu from "../layout/Menu";
import Footer from "../layout/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PLUS_ONE_API_URL } from "./baseURL";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";

const EditMoment = () => {
  const apiKey = "AIzaSyC3MDh4g3G1POPU4PBb2LoCz0YpPyg1InM";
  const mapApiJs = "https://maps.googleapis.com/maps/api/js";
  const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";
  const containerStyle = {
    width: "400px",
    height: "350px",
  };

  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };
  
  const location = useLocation();
  const [momentDetail, setMomentDetail] = useState({
    userName: "",
    contractorName: "",
    location: "",
    timeDate: {
      time: "",
      date: "",
    },
    plusOneResponse: "",
    moment_status: "",
    duration: "",
    amount: "",
    promoCode: "",
    lat: "",
    lng: "",
  });

  function loadAsyncScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src,
      });
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    });
  }

  const [disable, setDisable] = useState(false);
  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
    console.log(location.state);
    getMoment();
    initMapScript();
  }, [location.state]);

  const getMoment = () => {
    const loc = location.state.data;
    setMomentDetail({
      userName: loc.user_name,
      contractorName: loc.contractor_name,
      location: loc.address,
      timeDate: {
        time: loc.time,
        date: loc.date,
      },
      plusOneResponse: loc.plus_one_response,
      moment_status:
        loc.plus_one_check_in_out === 0
          ? "Not Started Yet"
          : loc.plus_one_check_in_out === 1
          ? "Moment Started"
          : "Moment Completed",
      duration: loc.duration,
      amount: loc.amount,
      promoCode: loc.promo_code,
      lat: loc.lat,
      lng: loc.long,
    });
  };

  return (
    <>
      <ToastContainer />
      <Loader />
      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />
        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/moments">Moments</Link>
            </li>
            <li className="breadcrumb-item active">Edit Moment</li>
          </ol>
          <h1 className="page-header">Edit Moment</h1>

          <div className="row">
            <div className="col-xl-12 ui-sortable">
              <div
                className="panel panel-inverse"
                data-sortable-id="form-stuff-10"
              >
                <div className="panel-heading ui-sortable-handle">
                  <h4 className="panel-title">Edit Category</h4>
                  <Link
                    to="/moments"
                    className="btn btn-sm btn-default pull-right"
                  >
                    Back
                  </Link>
                </div>

                <div className="panel-body">
                  <form>
                    <fieldset>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <label htmlFor="exampleInputName">User Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={momentDetail.userName}
                            placeholder="User Name"
                          />
                          <div className="text-danger"></div>
                        </div>
                        <div className="col-md-6 form-group">
                          <label htmlFor="exampleInputName">
                            Contractor Name:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={momentDetail.contractorName}
                            placeholder="Contractor Name"
                          />
                          <div className="text-danger"></div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-8 form-group">
                          <label htmlFor="exampleInputAddress">Location</label>
                          <input
                            className="form-control mb-10"
                            type="text"
                            readOnly
                            value={momentDetail.location}
                          />
                        </div>
                        <div className="col-md-4 form-group">
                          <label htmlFor="exampleInputTime">Date</label>
                          <input
                            className="form-control mb-10"
                            type="date"
                            readOnly
                            value={momentDetail.timeDate.date}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <label htmlFor="exampleInputTime">New Time</label>
                          <input
                            className="form-control mb-10"
                            type="text"
                            readOnly
                            value={momentDetail.timeDate.time["new"]}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <label htmlFor="exampleInputTime">Old Time</label>
                          <input
                            className="form-control mb-10"
                            type="text"
                            readOnly
                            value={momentDetail.timeDate.time["old"]}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <label htmlFor="exampleInputTime">
                            Moment Status
                          </label>
                          <input
                            className="form-control mb-10"
                            type="text"
                            readOnly
                            value={
                              momentDetail.moment_status === 0
                                ? "On Going"
                                : "Completed"
                            }
                          />
                        </div>
                        <div className="col-md-4 form-group">
                          <label htmlFor="exampleInputTime">
                            Plus One Response
                          </label>
                          <input
                            className="form-control mb-10"
                            type="text"
                            readOnly
                            value={momentDetail.plusOneResponse}
                          />
                        </div>
                        <div className="col-md-2 form-group">
                          <label htmlFor="exampleInputTime">
                            CheckIn-CheckOut Status
                          </label>
                          <br />
                          <input
                            type="button"
                            className="btn btn-primary"
                            value={momentDetail.moment_status}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 form-group">
                        <label htmlFor="exampleInputTime">Promo Code</label>
                          <br />
                          <input
                            className="form-control mb-10"
                            type="text"
                            readOnly
                            value={
                              momentDetail.promoCode
                                ? momentDetail.promoCode
                                : "No Promo Applied"
                            }
                          />
                        <label htmlFor="exampleInputTime">Amount</label>
                          <input
                            className="form-control mb-10"
                            type="text"
                            readOnly
                            value={momentDetail.amount?momentDetail.amount:''}
                          />
                        <label htmlFor="exampleInputTime">Duration</label>
                          <input
                            className="form-control mb-10"
                            type="text"
                            readOnly
                            value={momentDetail.duration?momentDetail.duration:''}
                          />
                          <label htmlFor="exampleInputTime">Latitude</label>
                          <input
                            className="form-control mb-10"
                            type="text"
                            readOnly
                            value={momentDetail.lat?momentDetail.lat:''}
                          />
                          <label htmlFor="exampleInputTime">Longitude</label>
                          <input
                            className="form-control mb-10"
                            type="text"
                            readOnly
                            value={momentDetail.lng?momentDetail.lng:''}
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          {momentDetail.lat && momentDetail.lng ? (
                            <div
                              className="set-schedule-map ml-4"
                              style={{ height: "200px", width: "200px" }}
                            >
                              <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={{
                                  lat: momentDetail.lat,
                                  lng: momentDetail.lng,
                                }}
                                zoom={18}
                              >
                                <Marker
                                  lat={momentDetail.lat}
                                  lng={momentDetail.long}
                                />
                              </GoogleMap>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default EditMoment;
