import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios';
import { PLUS_ONE_API_URL } from '../pages/baseURL';

function Menu() {
   const navigate = useNavigate();
   if (localStorage.getItem("PlusOne_Admin_Id") == null) {
      toast.error("Please login first...!");
      navigate(`/`);
   }

   const Logout = () => {
      localStorage.removeItem('PlusOne_Admin_Id');
      navigate(`/`);
   }

   const [admin, setAdmin] = useState({
      image: '',
      name: ''
   })
   var dashboardClass = window.location.pathname.match(/^\/dashboard/) ? "active" : "";
   var usersClass = window.location.pathname.match(/^\/users/) ? "active" : "";
   var contractorClass = window.location.pathname.match(/^\/contractors/) ? "active" : "";
   var categoryClass = window.location.pathname.match(/^\/category/) ? "active" : "";
   var topRatedClass = window.location.pathname.match(/^\/top-rated-moments/) ? "active" : "";
   var momentsClass = window.location.pathname.match(/^\/moments/) ? "active" : "";
   var voucherClass = window.location.pathname.match(/^\/voucher/) ? "active" : "";
   var giftCardClass = window.location.pathname.match(/^\/giftcard/) ? "active" : "";
   var howPlusOneClass = window.location.pathname.match(/^\/how-plusone-works/) ? "active" : "";
   var questionClass = window.location.pathname.match(/^\/question-answer/) ? "active" : "";
   var ratingClass = window.location.pathname.match(/^\/rating/) ? "active" : "";
   var feedbackClass = window.location.pathname.match(/^\/feedback/) ? "active" : "";


   const getData = () => {
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('id', localStorage.getItem("PlusOne_Admin_Id"));
      bodyFormData.append('auth_code', "PlUsOnE$123");
      const myurl = `${PLUS_ONE_API_URL}api/admin/get-profile`;
      axios({
         method: "post",
         url: myurl,
         data: bodyFormData,
         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }).then((response) => {
         if (response.data.success === true) {
            setAdmin({
               name: response.data.data.name,
               image: response.data.data.image
            })
         }
      });
   };

   useEffect(() => getData(), [])

   return (
      <>
         <div id="header" className="header navbar-default">
            <div className="navbar-header">
               <Link to="/dashboard" className="navbar-brand">
                  {/* <span className="navbar-logo"/> */}
                  <img src='site_logo2.png' style={{ marginRight: '5px' }} alt="logo" />
                  <b>PlusOne </b> Admin</Link>
            </div>
            <ul className="navbar-nav navbar-right">
               <li className="dropdown navbar-user">
                  <a className="dropdown-toggle" data-toggle="dropdown">
                     <img src={admin.image} alt="" />
                     <span className="d-none d-md-inline">{admin.name}</span> <b className="caret"></b>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                     <Link to="/profile" className="dropdown-item">Edit Profile</Link>
                     <a onClick={Logout} className="dropdown-item">Log Out</a>
                  </div>
               </li>
            </ul>
         </div>
         <div id="sidebar" className="sidebar">
            <div data-scrollbar="true" data-height="100%">

               <ul className="nav">
                  <li className="nav-header">Navigation</li>

                  <li className={dashboardClass}>
                     <Link to="/dashboard" >
                        <i className="fa fa-th-large"></i>
                        <span>Dashboard</span>
                     </Link>
                  </li>
                  <li className={usersClass}>
                     <Link to="/users" >
                        <i className="fa fa-users"></i>
                        <span>Users</span>
                     </Link>
                  </li>
                  {/* <li className={contractorClass}>
                     <Link to="/contractors" >
                        <i className="fa fa-user-secret"></i>
                        <span>Contractors</span>
                     </Link>
                  </li> */}
                  <li className={categoryClass}>
                     <Link to="/category" >
                        <i className="fa fa-shopping-basket"></i>
                        <span>Category</span>
                     </Link>
                  </li>
                  <li className={topRatedClass}>
                     <Link to="/top-rated-moments" >
                        <i className="fa fa-arrow-up"></i>
                        <span>Top Rated Moments</span>
                     </Link>
                  </li>
                  <li className={momentsClass}>
                     <Link to="/moments" >
                        <i className="fa fa-star"></i>
                        <span>Moments</span>
                     </Link>
                  </li>
                  <li className={voucherClass}>
                     <Link to="/voucher" >
                        <i class="fa fa-tags"></i>
                        <span>Voucher</span>
                     </Link>
                  </li>
                  <li className={giftCardClass}>
                     <Link to="/giftcard">
                        <i class="fa fa-gift"></i>
                        <span>Gift Card</span>
                     </Link>
                  </li>
                  <li className={howPlusOneClass}>
                     <Link to="/how-plusone-works">
                        <i class="fa fa-briefcase"></i>
                        <span>How Plus One Works</span>
                     </Link>
                  </li>
                  <li className={feedbackClass}>
                     <Link to="/feedback">
                        <i class="fa fa-comments"></i>
                        <span>Feedback</span>
                     </Link>
                  </li>
                  <li className={ratingClass}>
                     <Link to="/rating">
                        <i class="fa fa-percent"></i>
                        <span>Rating</span>
                     </Link>
                  </li>
                  <li className={questionClass}>
                     <Link to="/question-answer">
                        <i class="fa fa-question"></i>
                        <span>Questions and Answers</span>
                     </Link>
                  </li>
               </ul>
            </div>
         </div>

         <div className="sidebar-bg"></div>

      </>
   )
}

export default Menu
