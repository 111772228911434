import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../layout/Loader';
import Menu from '../layout/Menu';
import Footer from '../layout/Footer';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { PLUS_ONE_API_URL } from './baseURL';
import axios from 'axios';

const AddVoucher = () => {
    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';
        var element = document.getElementById("page-container");
        element.classList.add("show");
    }, []);

    const [VoucherDetail, setVoucherDetail] = useState({
        name: "",
        promoCode:"",
        voucher_amount:"",
        min_amount:"",
        expiry_date:""
    });
    const [errors, setErrors] = useState({});
    const [disable, setDisable] = useState(false);
    const navigate = useNavigate();

    const handleReset = () => {
        setVoucherDetail({
            name: "",
            promoCode:"",
            voucher_amount:"",
            min_amount:"",
            expiry_date:""
        })
    }

    const validateDetails = () => {
        let isValid = true;
        let err = {};

        if (!VoucherDetail.name) {
            isValid = false;
            err['name_err'] = "Please enter name";
        }

        if (!VoucherDetail.promoCode) {
            isValid = false;
            err['promoCode'] = "Please Enter Promo Code";
        }
        if (!VoucherDetail.voucher_amount) {
            isValid = false;
            err['voucher_amount'] = "Please Enter Voucher Amount";
        }
        if (!VoucherDetail.min_amount) {
            isValid = false;
            err['min_amount'] = "Please Enter Minimum Amount";
        }
        if (!VoucherDetail.expiry_date) {
            isValid = false;
            err['expiry_date'] = "Please select Expiry Date";
        }
        setErrors(err);
        return isValid;
    }



    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateDetails()) {
            setDisable(true);
            var bodyFormData = new URLSearchParams();
            bodyFormData.append('auth_code', "PlUsOnE$123");
            bodyFormData.append('voucher_name', VoucherDetail.name);
            bodyFormData.append('promo_code', VoucherDetail.promoCode);
            bodyFormData.append('voucher_amount', VoucherDetail.voucher_amount);
            bodyFormData.append('minimum_moment_amount', VoucherDetail.min_amount);
            bodyFormData.append('expiry_date', VoucherDetail.expiry_date);
            const myurl = `${PLUS_ONE_API_URL}api/admin/create-voucher`;
            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }).then((response) => {
                if (response.data.success === true) {
                    setDisable(false);
                    toast.success("Voucher added successfully");
                    setTimeout(() => {
                        navigate("/voucher");
                    }, [1000])
                } else {
                    setDisable(false);
                    toast.error("Something went wrong");
                }
            });
        }
    }
    return (
        <>
            <ToastContainer />
            <Loader />
            <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item"><Link to="/voucher">Voucher</Link></li>
                        <li className="breadcrumb-item active">Add Voucher</li>
                    </ol>
                    <h1 className="page-header">Add Voucher  </h1>

                    <div className="row">
                        <div className="col-xl-12 ui-sortable">
                            <div className="panel panel-inverse" data-sortable-id="form-stuff-10">
                                <div className="panel-heading ui-sortable-handle">
                                    <h4 className="panel-title">Add Voucher</h4>
                                    <Link to="/voucher" className="btn btn-sm btn-default pull-right">Back</Link>
                                </div>


                                <div className="panel-body">
                                    <form onSubmit={(e) => handleSubmit(e)}>
                                        <fieldset>

                                            <div className="row">
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="exampleInputName">Voucher Name:</label>
                                                    <input type="text" className="form-control" placeholder="Enter Voucher Name" value={VoucherDetail.name} onChange={(e) => setVoucherDetail({ ...VoucherDetail, name: e.target.value })} />
                                                    <div className="text-danger">{errors.name_err}</div>
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="exampleInputName">Promo Code</label>
                                                    <input type="text" className="form-control" placeholder="Enter Promo" value={VoucherDetail.promoCode} onChange={(e) => setVoucherDetail({ ...VoucherDetail, promoCode: e.target.value })} />
                                                    <div className="text-danger">{errors.promoCode}</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="exampleInputName">Voucher Amount</label>
                                                    <input type="text" className="form-control" placeholder="Enter Voucher Amount" value={VoucherDetail.voucher_amount} onChange={(e) => setVoucherDetail({ ...VoucherDetail, voucher_amount: e.target.value })} />
                                                    <div className="text-danger">{errors.voucher_amount}</div>
                                                </div>
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="exampleInputName">Minimum Amount</label>
                                                    <input type="text" className="form-control" placeholder="Enter Minimum Amount" value={VoucherDetail.min_amount} onChange={(e) => setVoucherDetail({ ...VoucherDetail, min_amount: e.target.value })} />
                                                    <div className="text-danger">{errors.min_amount}</div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 form-group">
                                                    <label htmlFor="exampleInputName">Expiry Date</label>
                                                    <input type="date" className="form-control" value={VoucherDetail.expiry_date} onChange={(e) => setVoucherDetail({ ...VoucherDetail, expiry_date: e.target.value })} />
                                                    <div className="text-danger">{errors.expiry_date}</div>
                                                </div>
                                            </div>

                                            <button type="submit" className="btn btn-sm btn-success m-r-5" disabled={disable} >{disable ? 'Processing...' : 'Submit'}</button>
                                            <button type="reset" className="btn btn-sm btn-default" onClick={handleReset}>Reset</button>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default AddVoucher