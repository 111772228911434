import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../layout/Loader';
import Menu from '../layout/Menu';
import Footer from '../layout/Footer';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { PLUS_ONE_API_URL } from './baseURL';
import axios from 'axios';

const AddGiftCard = () => {
    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';
        var element = document.getElementById("page-container");
        element.classList.add("show");
    }, []);

    const [giftcardDetail, setGiftcardDetail] = useState({
        image: ""
    });
    const [errors, setErrors] = useState({});
    const [disable, setDisable] = useState(false);
    const navigate = useNavigate();

    const handleImage = (e) => {
        let img = e.target.files[0];
        if (img.type === 'image/png' || img.type === 'image/jpg' || img.type === 'image/jpeg') {
            var bodyFormData = new FormData();
            bodyFormData.append('image', img)
            const myurl = `${PLUS_ONE_API_URL}api/admin/upload-img`;
            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/form-data' },
            }).then((response) => {
                if (response.data.success === true) {
                    let res = response.data.data;
                    setGiftcardDetail({ image: res.url });
                }
            });
        } else {
            setErrors({ ...errors, image_err: "Invalid image format" })
        }
    }

    const handleReset = () => {
        setGiftcardDetail({
            image: ""
        })
    }

    const validateDetails = () => {
        let isValid = true;
        let err = {};

        if (!giftcardDetail.image) {
            isValid = false;
            err['image_err'] = "Please select any image";
        }

        setErrors(err);
        return isValid;
    }

    const handleSubmit = (e) => {
        console.log(giftcardDetail.image);
        e.preventDefault();
        if (validateDetails()) {
            setDisable(true);
            var bodyFormData = new URLSearchParams();
            bodyFormData.append('auth_code', "PlUsOnE$123");
            bodyFormData.append('resource_image', giftcardDetail.image);
            bodyFormData.append('type', 0)
            const myurl = `${PLUS_ONE_API_URL}api/admin/add-image`;
            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            }).then((response) => {
                if (response.data.success === true) {
                    setDisable(false);
                    toast.success("GiftCard added successfully");
                    setTimeout(() => {
                        navigate("/giftcard");
                    }, [1000])
                    console.log(response);
                } else {
                    setDisable(false);
                    toast.error("Something went wrong");
                }
            });
        }
    }
    return (
        <>
            <ToastContainer />
            <Loader />
            <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item"><Link to="/giftcard">Giftcard</Link></li>
                        <li className="breadcrumb-item active">Add Giftcard</li>
                    </ol>
                    <h1 className="page-header">Add Giftcard  </h1>

                    <div className="row">
                        <div className="col-xl-6 ui-sortable">
                            <div className="panel panel-inverse" data-sortable-id="form-stuff-10">
                                <div className="panel-heading ui-sortable-handle">
                                    <h4 className="panel-title">Add Giftcard</h4>
                                    <Link to="/giftcard" className="btn btn-sm btn-default pull-right">Back</Link>
                                </div>


                                <div className="panel-body">
                                    <form onSubmit={(e) => handleSubmit(e)}>
                                        <fieldset>

                                            <div className="row">
                                                <div className="col-md-12 form-group">
                                                    <label htmlFor="exampleInputAddress">Image:</label>
                                                    <input className="form-control mb-10" type="file" accept="image/*" onChange={handleImage} />
                                                    {giftcardDetail.image ? <img src={giftcardDetail.image} alt="giftcard" style={{ width: "auto", height: "auto", objectFit: "cover", marginTop: "15px" }} /> : ""}
                                                    <div className="text-danger">{errors.image_err}</div>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-sm btn-success m-r-5" disabled={disable} >{disable ? 'Processing...' : 'Submit'}</button>
                                            <button type="reset" className="btn btn-sm btn-default" onClick={handleReset}>Reset</button>
                                        </fieldset>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default AddGiftCard