import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../layout/Loader'
import Menu from '../layout/Menu';
import Footer from '../layout/Footer';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { PLUS_ONE_API_URL } from './baseURL';

const TopRatedMoments = () => {

    let navigate = useNavigate();
    const [topRated, setTopRated] = useState([]);
    const [displayTopRated, setDisplayTopRated] = useState([]);
    const [deleteId, setDeleteId] = useState('');
    const [alert, setAlert] = useState(false);
    const [alert2, setAlert2] = useState(false);

    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';
        var element = document.getElementById("page-container");
        element.classList.add("show");
        getTopRatedList();
    }, []);

    const getTopRatedList = () => {
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('auth_code', "PlUsOnE$123");
        const myurl = `${PLUS_ONE_API_URL}api/admin/all-topRated`;
        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            if (response.data.success === true) {
                let res = response.data.data;
                setTopRated(res);
                setDisplayTopRated(res);
            }
        });
    };
    const handleDeleteTopRated = (id) => {
        setDeleteId(id);
        setAlert(true);
    }

    const DeleteTopRated = () => {
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('auth_code', "PlUsOnE$123");
        bodyFormData.append('top_id', deleteId);
        const myurl = `${PLUS_ONE_API_URL}api/admin/delete-topRated`;
        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            if (response.data.success === true) {
                getTopRatedList();
                setDeleteId('');
                setAlert(false);
                setAlert2(true);
            }
        })
    }

    const handleSearch = (e) => {
        if (e.target.value) {
            let search = e.target.value;
            setDisplayTopRated(topRated.filter(
                (elem) =>
                    elem.name.toLowerCase().includes(search.toLowerCase())
            ));
        } else {
            setDisplayTopRated(topRated)
        }
    }

    return (
        <Fragment>
            <Loader />
            <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Top Rated Moments</li>
                    </ol>
                    <h1 className="page-header">Top Rated Moments
                        <Link to="/add-top-rated-moments" className="btn btn-success" style={{ marginLeft: "10px" }}><i className="fa fa-plus"></i></Link>
                    </h1>
                    <div className="card">
                        <div className="card-body">
                            <div class="form-outline mb-4">
                                <input type="search" class="form-control" id="datatable-search-input" placeholder='Search Top Rated Moments' onChange={handleSearch} />
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table id="product-listing" className="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Name</th>
                                                    <th>Image</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displayTopRated.length > 0 ? displayTopRated.map((elem, index) =>
                                                    <tr key={index}>
                                                        <td align='left'>{index + 1}</td>
                                                        <td>{elem.name}</td>
                                                        <td><img src={elem.image} alt='user-img' style={{ width: '80px', height: '60px', borderRadius: '8px', objectFit: 'cover', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} /></td>
                                                        <td><i className='fa fa-edit' style={{ color: "green", cursor: "pointer" }} onClick={() => navigate('/edit-top-rated-moments', { state: { data: elem } })} ></i><i className='fa fa-trash' onClick={() => handleDeleteTopRated(elem.id)} style={{ marginLeft: "12px", color: "red", cursor: "pointer" }}></i></td>
                                                    </tr>
                                                ) : <tr><td >No Record Found..</td></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {alert === true ? <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={DeleteTopRated}
                    onCancel={() => { setAlert(false); setDeleteId(''); }}
                >
                    You will not be able to recover this top rated moment!
                </SweetAlert> : ''
                }
                {alert2 === true ? <SweetAlert
                    success
                    title="Top Rated Moment Deleted Successfully!"
                    onConfirm={() => { setAlert2(false); getTopRatedList(); }}
                />
                    : ''}
                <Footer />
            </div>
        </Fragment >
    )
}

export default TopRatedMoments