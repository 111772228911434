import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../layout/Loader'
import Menu from '../layout/Menu';
import Footer from '../layout/Footer';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { PLUS_ONE_API_URL } from './baseURL';
import parse from 'html-react-parser';

const QuestionAnswer = () => {

    let navigate = useNavigate();
    const [QuestionAnswer, setQuestionAnswer] = useState([]);
    const [displayQuestionAnswer, setDisplayQuestionAnswer] = useState([]);
    const [deleteId, setDeleteId] = useState('');
    const [alert, setAlert] = useState(false);
    const [alert2, setAlert2] = useState(false);
    
    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';
        var element = document.getElementById("page-container");
        element.classList.add("show");
        getQuestionAnswerList();
    }, []);

    const getQuestionAnswerList = () => {
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('auth_code', "PlUsOnE$123");
        const myurl = `${PLUS_ONE_API_URL}api/admin/list-of-question-answer?auth_code=PlUsOnE$123`;
        axios({
            method: "get",
            url: myurl,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            console.log(response.data.data);
            if (response.data.success === true) {
                let res = response.data.data;
                setQuestionAnswer(res);
                setDisplayQuestionAnswer(res);
            }
        });
    };

    const handleDeleteQuestionAnswer = (id) => {
        setDeleteId(id);
        setAlert(true);
    }

    const DeleteQuestionAnswer = () => {
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('auth_code', "PlUsOnE$123");
        bodyFormData.append('question_id', deleteId);
        const myurl = `${PLUS_ONE_API_URL}api/admin/delete-question-and-answer`;
        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            if (response.data.success === true) {
                getQuestionAnswerList();
                setDeleteId('');
                setAlert(false);
                setAlert2(true);
            }
        })
    }

    const add3Dots = (string, limit) => {
        var dots = "...";
        if (string.length > limit) {
            string = string.substring(0, limit) + dots;
        }
        return string;
    }

    const handleSearch = (e) => {
        if (e.target.value) {
            let search = e.target.value;
            setDisplayQuestionAnswer(QuestionAnswer.filter(
                (elem) =>
                    elem.id.toLowerCase().includes(search.toLowerCase())
            ));
        } else {
            setDisplayQuestionAnswer(QuestionAnswer)
        }
    }

    return (
        <Fragment>
            <Loader />
            <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Question Answer</li>
                    </ol>
                    <h1 className="page-header">Question Answer
                        <Link to="/add-question-answer" className="btn btn-success" style={{ marginLeft: "10px" }}><i className="fa fa-plus"></i></Link>
                    </h1>
                    <div className="card">
                        <div className="card-body">
                            {/* <div class="form-outline mb-4">
                                <input type="search" class="form-control" id="datatable-search-input" placeholder='Search Category' onChange={handleSearch} />
                            </div> */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table id="product-listing" className="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Question</th>
                                                    <th>Answer</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displayQuestionAnswer.length > 0 ? displayQuestionAnswer.map((elem, index) =>
                                                    <tr key={index}>
                                                        <td align='left'>{index + 1}</td>
                                                        <td>{add3Dots(elem.question,40)}</td>
                                                        <td>{add3Dots(elem.answer,50)}</td>
                                                        <td><i className='fa fa-trash' onClick={() => handleDeleteQuestionAnswer(elem.id)} style={{ marginLeft: "12px", color: "red", cursor: "pointer" }}></i></td></tr>
                                                ) : <tr><td >No Record Found..</td></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {alert === true ? <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={DeleteQuestionAnswer}
                    onCancel={() => { setAlert(false); setDeleteId(''); }}
                >
                    You will not be able to recover this category!
                </SweetAlert> : ''
                }
                {alert2 === true ? <SweetAlert
                    success
                    title="Category Deleted Successfully!"
                    onConfirm={() => { setAlert2(false); getQuestionAnswerList(); }}
                />
                    : ''}
                <Footer />
            </div>
        </Fragment >
    )
}

export default QuestionAnswer