import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../layout/Loader'
import Menu from '../layout/Menu';
import Footer from '../layout/Footer';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { PLUS_ONE_API_URL } from './baseURL';
import parse from 'html-react-parser';

const HowPlusOneWorkds = () => {

    let navigate = useNavigate();
    const [HowPlusOne, setHowPlusOne] = useState([]);
    const [displayHowPlusOne, setDisplayHowPlusOne] = useState([]);
    const [deleteId, setDeleteId] = useState('');
    const [alert, setAlert] = useState(false);
    const [alert2, setAlert2] = useState(false);

    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';
        var element = document.getElementById("page-container");
        element.classList.add("show");
        getHowPlusOneList();
    }, []);

    const getHowPlusOneList = () => {
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('auth_code', "PlUsOnE$123");
        const myurl = `${PLUS_ONE_API_URL}api/admin/list-plus-one-step?auth_code=PlUsOnE$123`;
        axios({
            method: "get",
            url: myurl,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            if (response.data.success === true) {
                let res = response.data.data;
                setHowPlusOne(res);
                setDisplayHowPlusOne(res);
            }
        });
    };

    const add3Dots = (string, limit) => {
        var dots = "...";
        if (string.length > limit) {
            string = string.substring(0, limit) + dots;
        }
        return string;
    }

    const handleSearch = (e) => {
        if (e.target.value) {
            let search = e.target.value;
            setDisplayHowPlusOne(HowPlusOne.filter(
                (elem) =>
                    elem.id.toLowerCase().includes(search.toLowerCase())
            ));
        } else {
            setDisplayHowPlusOne(HowPlusOne)
        }
    }

    return (
        <Fragment>
            <Loader />
            <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item active">How PlusOne Works</li>
                    </ol>
                    <h1 className="page-header">How PlusOne Works
                        <Link to="/add-how-plusone-works" className="btn btn-success" style={{ marginLeft: "10px" }}><i className="fa fa-plus"></i></Link>
                    </h1>
                    <div className="card">
                        <div className="card-body">
                            {/* <div class="form-outline mb-4">
                                <input type="search" class="form-control" id="datatable-search-input" placeholder='Search Category' onChange={handleSearch} />
                            </div> */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table id="product-listing" className="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Title</th>
                                                    <th>Video</th>
                                                    <th>Description Available</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displayHowPlusOne.length > 0 ? displayHowPlusOne.map((elem, index) =>
                                                    <tr key={index}>
                                                        <td align='left'>{elem.serial_no}</td>
                                                        <td>{elem.title}</td>
                                                        <td> <video src={elem.video} alt="giftcard" style={{ width: "auto", height: "150px", objectFit: "cover", marginTop: "15px" }} controls /></td>
                                                        <td>{elem.description ? 'True' : 'False'}</td>
                                                        <td><i className='fa fa-edit' style={{ color: "green", cursor: "pointer" }} onClick={() => navigate('/edit-how-plusone-works', { state: { data: elem } })} ></i></td></tr>
                                                ) : <tr><td >No Record Found..</td></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </Fragment >
    )
}

export default HowPlusOneWorkds