import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../layout/Loader'
import Menu from '../layout/Menu';
import Footer from '../layout/Footer';

import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { PLUS_ONE_API_URL } from './baseURL';

const Contractors = () => {

    let navigate = useNavigate();
    const [contractors, setContractors] = useState([]);
    const [displayContractors, setDisplayContractors] = useState([]);
    const rows = [10, 20, 30];;
    const [contractorsPerPage, setContractorsPerPage] = useState(rows[0]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        document.getElementById('page-loader').style.display = 'none';
        var element = document.getElementById("page-container");
        element.classList.add("show");
        getContractorsData();
    }, []);

    const getContractorsData = () => {
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('auth_code', "PlUsOnE$123");
        const myurl = `${PLUS_ONE_API_URL}api/admin/get-users`;
        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }).then((response) => {
            console.log(response.data.data);
            if (response.data.success === true) {
                let res = response.data.data;
                let arr = [];
                res.map((elem) => {
                    if(elem.is_switch_to_contractor){
                    arr.push({ ...elem, full_name: elem.first_name + ' ' + elem.last_name, phone_number: elem.country_code + elem.mobile })
                    }
                })
                setContractors(arr);
            }
        });
    };

    useEffect(() => {
        setDisplayContractors(contractors)
    }, [contractors]);

    const indexOfLastContractor = currentPage * contractorsPerPage;
    const indexOfFirstContractor = indexOfLastContractor - contractorsPerPage;
    const currentContractors = displayContractors.slice(indexOfFirstContractor, indexOfLastContractor);

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(displayContractors.length / contractorsPerPage); i++) {
        pageNumbers.push(i);
    }

    const paginate = (num) => {
        setCurrentPage(num);
    }

    const handleSearch = (e) => {
        if (e.target.value) {
            let search = e.target.value;
            setDisplayContractors(contractors.filter(
                (elem) =>
                    elem.full_name.toLowerCase().includes(search.toLowerCase()) ||
                    elem.email.toLowerCase().includes(search.toLowerCase()) ||
                    elem.phone_number.toLowerCase().includes(search.toLowerCase())
            ));
        } else {
            setDisplayContractors(contractors)
        }
    }

    return (
        <Fragment>
            <Loader />
            <div id="page-container" className="fade page-sidebar-fixed page-header-fixed">
                <Menu />
                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                        <li className="breadcrumb-item active">Contractors</li>
                    </ol>
                    <h1 className="page-header">Contractors
                        {/* <Link to="/add-user" className="btn btn-success" ><i className="fa fa-plus"></i></Link> */}
                    </h1>
                    <div className="card">
                        <div className="card-body">
                            <div class="form-outline mb-4">
                                <input type="search" class="form-control" id="datatable-search-input" placeholder='Search Contractor' onChange={handleSearch} />
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table id="product-listing" className="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Full Name</th>
                                                    <th>Profile Pic</th>
                                                    <th>Email</th>
                                                    <th>Phone Number</th>
                                                    <th>Security Number</th>
                                                    <th>User Type</th>
                                                    {/* <th>Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentContractors.length > 0 ? currentContractors.map((elem, index) =>
                                                    <tr key={index}>
                                                        <td align='left'>{index + 1}</td>
                                                        <td>{elem.full_name}</td>
                                                        <td><img src={elem.profile_pic} alt='user-img' style={{ width: '50px', height: '50px', borderRadius: '8px', objectFit: 'cover', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} /></td>
                                                        <td>{elem.email}</td>
                                                        <td>{elem.phone_number ? elem.phone_number : ''}</td>
                                                        <td>{elem.security_number}</td>
                                                        <td>{elem.type === 1 ? 'Regular' : elem.type === 2 ? 'Google' : 'Facebook'}</td>
                                                        {/* <td><i className='fa fa-edit' style={{ color: "green", cursor: "pointer" }} onClick={() => history.push({ pathname: '/edit-collection', state: elem })} ></i><i className='fa fa-trash' onClick={() => handleDeleteCollection(elem.id)} style={{ marginLeft: "12px", color: "red", cursor: "pointer" }}></i></td> */}
                                                    </tr>
                                                ) : <tr><td >No Record Found..</td></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="gt-pagination" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <ul class="pagination">
                                            {pageNumbers.map((number) =>
                                                <li class={currentPage === number ? "page-item active" : "page-item"} aria-current="page">
                                                    <span class="page-link" onClick={() => paginate(number)}>{number}</span>
                                                </li>
                                            )}
                                        </ul>
                                        <div className='filter-pages' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <label htmlFor='selection' style={{ marginBottom: '0' }}>Collections Per Page :</label>
                                            <select className='selection' style={{ outline: '0', borderWidth: '0 0 1px', borderColor: 'black', marginLeft: '10px' }} onChange={(e) => setContractorsPerPage(e.target.value)}>
                                                {rows.map(value =>
                                                    <option value={value}>{value}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {alert === true ? <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure?"
                    onConfirm={DeleteUser}
                    onCancel={() => { setAlert(false); setDeleteId(''); }}
                >
                    You will not be able to recover this user!
                </SweetAlert> : ''
                }
                {alert2 === true ? <SweetAlert
                    success
                    title="User Deleted Successfully!"
                    onConfirm={() => { setAlert2(false); getUserData(); }}
                />
                    : ''} */}
                <Footer />
            </div>
        </Fragment>
    )
}

export default Contractors
