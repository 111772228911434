import React, { useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../layout/Loader";
import Menu from "../layout/Menu";
import Footer from "../layout/Footer";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import { PLUS_ONE_API_URL } from "./baseURL";
import { Switch } from "@material-ui/core";

const Moments = () => {
  let navigate = useNavigate();
  const [moments, setMoments] = useState([]);
  const [displayMoments, setDisplayMoments] = useState([]);
  const rows = [10, 20, 30];
  const [momentsPerPage, setMomentsPerPage] = useState(rows[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";
    var element = document.getElementById("page-container");
    element.classList.add("show");
    getMomentsList("none", "none");
  }, []);
  const getMomentsList = (startDate, endDate) => {
    let user_arr = [];
    var bodyFormData2 = new URLSearchParams();
    bodyFormData2.append("auth_code", "PlUsOnE$123");
    const myurl2 = `${PLUS_ONE_API_URL}api/admin/get-users`;
    axios({
      method: "post",
      url: myurl2,
      data: bodyFormData2,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        if (response.data.success === true) {
          let res = response.data.data;
          let arr = [];
          res.map((elem) => {
            arr.push({
              ...elem,
              full_name: elem.first_name + " " + elem.last_name,
              phone_number: elem.country_code + elem.mobile,
            });
          });
          user_arr = arr;
          var bodyFormData3 = new URLSearchParams();
          bodyFormData3.append("auth_code", "PlUsOnE$123");
          const myurl4 = `${PLUS_ONE_API_URL}api/admin/all-moments`;
          axios({
            method: "post",
            url: myurl4,
            data: bodyFormData3,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }).then((response) => {
            console.log(response.data.data);
            if (response.data.success === true) {
              let res = response.data.data;
              let moment_arr = [];
              if (startDate === "none" && endDate === "none") {
                res.map((elem) => {
                  let contractor_name = "";
                  let user_name = "";
                  user_arr.map((cont) => {
                    if (cont.id === elem.plus_one_id) {
                      contractor_name = cont.first_name + " " + cont.last_name;
                    }
                  });
                  user_arr.map((us) => {
                    if (us.id === elem.user_id) {
                      user_name = us.first_name + " " + us.last_name;
                    }
                  });
                  moment_arr.push({ ...elem, contractor_name, user_name });
                });
              } else {
                res.map((elem) => {
                  if (elem.date >= from && elem.date <= to) {
                    let contractor_name = "";
                    let user_name = "";
                    user_arr.map((cont) => {
                      if (cont.id === elem.plus_one_id) {
                        contractor_name =
                          cont.first_name + " " + cont.last_name;
                      }
                    });
                    user_arr.map((us) => {
                      if (us.id === elem.user_id) {
                        user_name = us.first_name + " " + us.last_name;
                      }
                    });
                    moment_arr.push({ ...elem, contractor_name, user_name });
                  }
                });
              }
              setMoments(moment_arr);
            } else {
              console.log("33");
            }
          });
        } else {
          console.log("22");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setDisplayMoments(moments);
  }, [moments]);

  const indexOfLastMoment = currentPage * momentsPerPage;
  const indexOfFirstMoment = indexOfLastMoment - momentsPerPage;
  const currentMoments = displayMoments.slice(
    indexOfFirstMoment,
    indexOfLastMoment
  );

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(displayMoments.length / momentsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (num) => {
    setCurrentPage(num);
  };

  const StatusDetail = (e) => {
    switch (e) {
      case -1:
        return "Cancelled";
      case 0:
        return "Not Started Yet";
      case 1:
        return "In Process";
        case 2:
            return "Completed";
    }
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      let search = e.target.value;
      setDisplayMoments(
        moments.filter(
          (elem) =>
            elem.contractor_name.toLowerCase().includes(search.toLowerCase()) ||
            elem.user_name.toLowerCase().includes(search.toLowerCase()) ||
            elem.address.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setDisplayMoments(moments);
    }
  };

  const handleApply = () => {
    if (from && to) {
      getMomentsList(from, to);
      setCurrentPage(1);
    }
  };

  const handleReset = () => {
    getMomentsList("none", "none");
    setCurrentPage(1);
    setFrom("");
    setTo("");
  };

  return (
    <Fragment>
      <Loader />
      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />
        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Moments</li>
          </ol>
          <h1 className="page-header">
            Moments
            {/* <Link to="/add-user" className="btn btn-success" ><i className="fa fa-plus"></i></Link> */}
          </h1>
          <div className="row date-filter d-flex align-items-center justify-content-sm-end m-r-10 m-b-10">
            <label>From:</label>
            <input
              type="date"
              value={from}
              onChange={(e) => {
                setFrom(e.target.value);
              }}
              className="form-control col-md-3 ml-2 mr-4"
            />
            <label>To:</label>
            <input
              type="date"
              value={to}
              onChange={(e) => {
                setTo(e.target.value);
              }}
              className="form-control col-md-3 ml-2"
            />
            <button className="btn btn-success ml-2" onClick={handleApply}>
              Apply
            </button>
            <button className="btn btn-danger ml-2" onClick={handleReset}>
              Reset / Clear
            </button>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="form-outline mb-4">
                <input
                  type="search"
                  className="form-control"
                  id="datatable-search-input"
                  placeholder="Search Moment"
                  onChange={handleSearch}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table id="product-listing" className="table">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>User Name</th>
                          <th>Contractor Name</th>
                          <th>Location</th>
                          <th>Date & Time</th>
                          <th>Status</th>
                          <th>Actions</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {currentMoments.length > 0 ? (
                          currentMoments.map((elem, index) => (
                            <tr key={index}>
                              <td align="left">{index + 1}</td>
                              <td>
                                {elem.user_name
                                  ? elem.user_name
                                  : "User not Exist"}
                              </td>
                              <td>
                                {elem.contractor_name
                                  ? elem.contractor_name
                                  : "Contractor not Exist"}
                              </td>
                              <td>{elem.address}</td>
                              <td>{elem.date + ", " + elem.time.new}</td>
                              <td>{StatusDetail(elem.moment_status)}</td>
                              <td>
                                <i
                                  className="fa fa-edit"
                                  style={{ color: "green", cursor: "pointer" }}
                                  onClick={() =>
                                    navigate("/edit-moments", {
                                      state: { data: elem },
                                    })
                                  }
                                ></i>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No Record Found..</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="gt-pagination"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <ul className="pagination">
                      {pageNumbers.map((number) => (
                        <li
                          className={
                            currentPage === number
                              ? "page-item active"
                              : "page-item"
                          }
                          aria-current="page"
                        >
                          <span
                            className="page-link"
                            onClick={() => paginate(number)}
                          >
                            {number}
                          </span>
                        </li>
                      ))}
                    </ul>
                    <div
                      className="filter-pages"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label htmlFor="selection" style={{ marginBottom: "0" }}>
                        Moments Per Page :
                      </label>
                      <select
                        className="selection"
                        style={{
                          outline: "0",
                          borderWidth: "0 0 1px",
                          borderColor: "black",
                          marginLeft: "10px",
                        }}
                        onChange={(e) => setMomentsPerPage(e.target.value)}
                      >
                        {rows.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Moments;
